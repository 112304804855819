<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="w-100 cart">
		<div :class="['xs','sm'].includes(windowWidth) ? 'h-80' : 'h-55'" />
		<div class="border-0 bg-white mb-0">
			<!-- Main product -->
			<div v-if="ticketsDetailLoading">
				<is-loading
					:loading="ticketsDetailLoading"
					:loading-label="translate('loading')"
					:no-data-label="translate('data_not_found')" />
			</div>
			<div v-else>
				<!-- Image / data -->
				<div class="row">
					<div
						class="col-md-5 col-sm-12 event-bg py-3"
						:style="`background-image: url(${S3_PATH}/assets/products/${theme}/backgrounds/${eventCodeName}.png)`">
						<!-- Back to store link -->
						<div
							class="row mx-auto mb-5 container">
							<div class="col-12">
								<a @click="$router.push({ name: 'Store' })">
									<div class="btn btn-link pointer text-decoration-none p-0 text-white">
										<div>
											<i class="fas fa-chevron-left mr-2" />
											<span class="font-weight-bold">{{ translate('back_to_store') }}</span>
										</div>
									</div>
								</a>
							</div>
						</div>
						<div class="event-info-container text-white">
							<h1
								class="event-title"
								v-html="translate(`${eventCodeName}_html`)" />
							<p v-html="translate(`${eventCodeName}_description`)" />
						</div>
					</div>
					<!-- Dates container -->
					<div class="col-md-7 col-sm-12 event-dates-info-container">
						<h1>{{ translate('event_dates_info_title') }}</h1>
						<p v-html="translate('event_dates_info')" />
						<div class="row no-gutters">
							<!-- Card Container -->
							<div
								v-for="(eventDate, index) in eventDatesDetail"
								:key="index"
								class="col-md-6 col-sm-12">
								{{ eventDate.code_name }}
								<event-date-card
									:event-id="eventDate.id"
									:event-info="eventDate.attributes"
									class="mr-2 mb-2"
									@showTicketInfo="showTicketInfo(eventDate)" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<custom-modal
			:show.sync="showTicketModal"
			:pre-title="!showDataSaved ? translate('ticket_modal_pre_title') : ''"
			:close-text="translate('close')"
			ref-name="ticketModal"
			:title="!showDataSaved ? translate('ticket_modal_title') : translate('reservation_complete')"
			:modal-size="['xs', 'sm'].includes(windowWidth) ? 'xl' : 'lg-custom-2'">
			<div v-if="loadingInfo">
				<is-loading
					:loading="loadingInfo"
					:loading-label="translate('loading')"
					:no-data-label="translate('data_not_found')" />
			</div>
			<div v-else-if="showDataSaved">
				<div
					class="text-center custom-modal-body px-3"
					v-html="translate('reservation_complete_info')" />
				<h1
					class="text-center text-success px-3 pt-2"
					v-html="fullEventInfo" />
				<p
					class="text-center px-3"
					v-html="fullInfo.address" />
				<div class="text-center custom-modal-body px-3 pt-2">
					<b>{{ translate('full_name') }}:</b> {{ form.first_name }} {{ form.last_name }}
				</div>
				<div class="text-center custom-modal-body px-3 pt-1">
					<b>{{ translate('extra_guest') }}:</b> {{ form.guest_number }}
				</div>
			</div>
			<div v-else-if="!showCancelConfirmation">
				<div
					class="text-center custom-modal-body px-3"
					v-html="translate('event_confirm_attendance', {'event': translate(eventCodeName)})" />
				<h1
					class="text-center text-success px-3 pt-2"
					v-html="fullInfo.date" />
				<p
					class="text-center px-3"
					v-html="fullInfo.address" />
				<div
					class="text-center custom-modal-body px-3"
					v-html="translate('event_confirm_info')" />
				<hr
					:class="{
						'mx-3': ['xs', 'sm'].includes(windowWidth),
						'mx-6': !['xs', 'sm'].includes(windowWidth),
					}"
					class="mt-3 mb-2">
				<div
					:class="{
						'mx-1': ['xs', 'sm'].includes(windowWidth),
						'px-6': !['xs', 'sm'].includes(windowWidth),
					}"
					class="row custom-modal-labels px-3">
					<div class="col-6">
						<div class="form-group">
							<input-text
								id="first_name"
								:disabled="!canSaveTicket"
								:label="translate('first_name')"
								:setter-value="form.first_name"
								:errors="errors['first_name']"
								:required="true"
								placeholder=" "
								type="text"
								@dataChanged="form.first_name = $event" />
						</div>
					</div>
					<div class="col-6">
						<div class="form-group">
							<input-text
								id="last_name"
								:disabled="!canSaveTicket"
								:label="translate('last_name')"
								:setter-value="form.last_name"
								:errors="errors['last_name']"
								:required="true"
								placeholder=" "
								type="text"
								@dataChanged="form.last_name = $event" />
						</div>
					</div>
				</div>
				<hr
					:class="{
						'mx-3': ['xs', 'sm'].includes(windowWidth),
						'mx-6': !['xs', 'sm'].includes(windowWidth),
					}"
					class="mt-2 mb-3">
				<div
					:class="{
						'mx-1': ['xs', 'sm'].includes(windowWidth),
						'px-6': !['xs', 'sm'].includes(windowWidth),
					}"
					class="row custom-modal-labels px-3 mb-3">
					<div class="col-md-6 col-sm-12">
						{{ translate('extra_guest_info') }}
					</div>
					<div class="col-md-6 col-sm-12 pt-2">
						<div class="form-group">
							<div
								:class="['xs', 'sm'].includes(windowWidth) ? 'px-4': ''">
								<number-input-spinner
									v-if="canSaveTicket"
									v-model="form.guest_number"
									:mouse-down-speed="500"
									:min="0"
									:max="99"
									:button-class="'vnis__button bg-primary-alt'"
									:input-class="'vnis__input custom-input-width'"
									:integer-only="true"
									class="col" />
								<input
									v-else
									class="form-control text-center"
									type="text"
									disabled
									:value="form.guest_number">
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-else>
				<div
					class="text-center h4 custom-modal-body px-3">
					{{ translate('cancel_confirmation') }}
				</div>
				<div
					class="text-center custom-modal-body px-3 mb-4">
					{{ translate('cancel_confirmation_info') }}
				</div>
			</div>
			<template #footer>
				<div v-if="!loadingInfo">
					<div
						v-if="showDataSaved"
						class="d-flex justify-content-center text-center mt-1">
						<button
							class="btn btn-primary"
							@click="hideModal()">
							{{ translate('continue') }}
						</button>
					</div>
					<div v-else-if="!showCancelConfirmation">
						<div class="d-flex justify-content-center text-center">
							<div
								v-if="canSaveTicket"
								class="bg-primary py-2 px-5 pointer font-weight-bold"
								style="min-width: 25%;"
								@click="createNewTicket()">
								<span class="text-white">
									{{ translate(!currentHasTicket ? 'ticket_modal_save' : 'ticket_modal_update') }}
								</span>
							</div>
						</div>
						<div
							v-if="currentHasTicket"
							class="d-flex justify-content-center text-center mt-1">
							<div
								class="py-2">
								<span
									class="text-muted pointer"
									:class="canSaveTicket ? 'text-muted pointer' : 'bg-secondary py-2 px-5 pointer font-weight-bold'"
									@click="cancelConfirmation()">
									{{ translate('ticket_modal_cancel') }}
								</span>
							</div>
						</div>
					</div>
					<div
						v-else
						class="d-flex justify-content-center text-center mt-1">
						<button
							class="btn btn-success mr-3"
							@click="cancel()">
							{{ translate('cancel_reservation_ok') }}
						</button>
						<button
							class="btn btn-secondary"
							@click="showCancelConfirmation = false">
							{{ translate('cancel_reservation_go_back') }}
						</button>
					</div>
				</div>
				<div v-else />
			</template>
		</custom-modal>
	</div>
</template>

<script>
import NumberInputSpinner from 'vue-number-input-spinner';
import WindowSizes from '@/mixins/WindowSizes';
import { NOT_FOUND } from '@/settings/Errors';
import { S3_PATH } from '@/settings/Images';
import { DO_FORMAT } from '@/settings/Dates';
import {
	Store, FreeEvents, Dates,
} from '@/translations';
import EventTickets from '@/util/EventTickets';
import InputText from '@/components/InputText';
import isLoading from '@/components/Loading';
import CustomModal from '@/components/CustomModal';
import EventDateCard from './components/EventDateCard.vue';

export default {
	name: 'FreeEventsDetails',
	messages: [Store, FreeEvents, Dates],
	components: {
		isLoading,
		EventDateCard,
		CustomModal,
		InputText,
		NumberInputSpinner,
	},
	mixins: [WindowSizes],
	data() {
		return {
			NOT_FOUND,
			eventDates: new EventTickets(),
			eventDateInfo: new EventTickets(),
			createFreeTicket: new EventTickets(),
			alert: new this.$Alert(),
			tabIndex: 0,
			showTicketModal: false,
			S3_PATH,
			theme: process.env.VUE_APP_THEME,
			canSaveTicket: false,
			showCancelConfirmation: false,
			showDataSaved: false,
			form: {
				first_name: '',
				last_name: '',
				guest_number: 0,
			},
			currentTicketInfo: null,
		};
	},
	computed: {
		ticketsDetailLoading() {
			return !!this.eventDates.data.loading;
		},
		eventCodeName() {
			const { eventCodeName } = this.$route.params;
			return eventCodeName;
		},
		eventDatesDetail() {
			try {
				const { data } = this.eventDates.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		fullEventInfo() {
			return `${this.translate(this.eventCodeName)} - ${this.fullInfo.date}`;
		},
		selectedEventInfo() {
			try {
				const { data } = this.eventDateInfo.data.response.data.response;
				return data;
			} catch (error) {
				return [];
			}
		},
		errors() {
			try {
				return this.createFreeTicket.data.errors.errors;
			} catch (error) {
				return [];
			}
		},
		eventDateInfoLoading() {
			return !!this.eventDateInfo.data.loading;
		},
		createTicketLoading() {
			return !!this.createFreeTicket.data.loading;
		},
		loadingInfo() {
			return this.eventDateInfoLoading || this.createTicketLoading || this.ticketsDetailLoading;
		},
		fullInfo() {
			if (this.currentTicketInfo !== null) {
				const eventMonth = this.translate(this.currentTicketInfo.attributes.month);
				const dayFormatted = this.$moment(this.currentTicketInfo.attributes.date.start_date).format(DO_FORMAT);

				return {
					date: `${eventMonth} ${dayFormatted}`,
					address: `<b>${this.translate(`${this.currentTicketInfo.attributes.code_name}_address`)}</b>`,
				};
			}

			return {};
		},
		currentHasTicket() {
			if (this.currentTicketInfo !== null) {
				return this.currentTicketInfo.attributes.has_ticket;
			}
			return false;
		},
	},
	watch: {
		selectedEventInfo(value) {
			this.form.first_name = value.first_name;
			this.form.last_name = value.last_name;
			this.form.guest_number = value.guest_number;
			this.canSaveTicket = value.can_edit;
		},
	},
	mounted() {
		this.initEventInfo();
	},
	methods: {
		initEventInfo() {
			this.eventDates.getFreeEventTicketsDates(this.eventCodeName).catch((error) => {
				if (this.NOT_FOUND.includes(error.status)) this.$router.push({ name: 'Store' });
			});
		},
		getEventInfo(id) {
			this.eventDateInfo.getFreeEventTicketInfo(id);
		},
		returnToStore() {
			this.$router.push({ name: 'Store' });
		},
		showTicketInfo(eventDate) {
			this.showCancelConfirmation = false;
			this.currentTicketInfo = eventDate;
			this.showTicketModal = true;
			this.getEventInfo(eventDate.id);
		},
		createNewTicket() {
			const payload = {
				first_name: this.form.first_name,
				last_name: this.form.last_name,
				guest_number: this.form.guest_number,
				event_code_name: this.currentTicketInfo.attributes.code_name,
			};

			this.createFreeTicket.createFreeEventTicket(payload).then(() => {
				this.showDataSaved = true;
				this.initEventInfo();
			}).catch(() => {
				if (typeof this.errors.event_code_name !== 'undefined') {
					this.errors.event_code_name.forEach((message) => {
						this.alert.toast('error', message);
					});
				}
			});
		},
		cancelConfirmation() {
			this.showCancelConfirmation = true;
		},
		cancel() {
			this.createFreeTicket.deleteFreeTicket(this.currentTicketInfo.id).then(() => {
				this.alert.toast('success', this.translate('ticket_deleted'));
				this.showTicketModal = false;
				this.initEventInfo();
			}).catch(() => {
				this.alert.toast('error', 'Error al borrar el ticket');
			});
		},
		hideModal() {
			this.showCancelConfirmation = false;
			this.showTicketModal = false;
			this.showDataSaved = false;
		},
	},
};
</script>
<style scoped>
.event-bg {
	background-size: cover;
	background-position: right;
}
.event-title {
	font-size: 3rem;
}
.event-info-container {
	padding-left: 3rem;
	padding-right: 4rem;
	padding-bottom: 5rem;
}
.event-dates-info-container {
	padding-left: 2rem;
	padding-right: 2rem;
	padding-top: 3rem;
}

.h-55 {
	height: 55px;
}
.h-80 {
	height: 80px;
}
.custom-btn.custom-primary-btn{
	width: 150px;
	border-radius: 25px;
}
.mx-6 {
	margin-left: 6rem !important;
	margin-right: 6rem !important;
}
.px-6 {
	margin-left: 6rem !important;
	margin-right: 6rem !important;
}
.custom-input-width {
	width: 70px !important;
}
</style>
