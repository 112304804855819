<template>
	<div>
		<b-card
			no-body
			:class="{
				'card-hover-mobile': ['xs', 'sm'].includes(windowWidth),
				'card-hover': !['xs', 'sm'].includes(windowWidth),
			}"
			class="overflow-hidden card-shadow card-hover-mobile">
			<div class="row no-gutters">
				<div
					class="col-2"
					:style="eventDayStyle">
					<div
						class="card-body p-1 text-center event-day event-day-text"
						:style="eventDayStyle">
						<span> {{ eventMonth }} <br> {{ eventDay }} </span>
					</div>
				</div>
				<div
					:class="eventInfo.guest_number > 0 ? 'col-8' : 'col-10'"
					class="pl-1 pt-1 "
					:style="headerStyle">
					<span class="m-0">{{ stateCity }}</span>
					<!-- <br>
					<span class="mb-1">{{ eventTime }}</span> -->
				</div>
				<div
					v-if="eventInfo.guest_number > 0"
					class="col-2"
					:style="headerStyle">
					<div
						class="card-body p-1 text-right pr-2 event-day"
						:style="headerStyle">
						<span class="fa fa-user ml-1" />
						<span> + {{ eventInfo.guest_number }} </span>
					</div>
				</div>
			</div>
			<hr class="m-0">
			<div class="row no-gutters">
				<div
					:class="['xs', 'sm'].includes(windowWidth) ? 'pt-2 px-2' : 'p-2' "
					class="col-12">
					<span class="fa fa-map-marker text-success mr-2" /><b class="ml-1">{{ location }}</b>
					<br>
					<span class="fa fa-comments mr-2" /><span>{{ extraInfo }}</span>
				</div>
				<div
					class="p-2 col-12"
					style="text-align: right">
					<div
						:class="{
							'btn-green': eventInfo.has_ticket,
							'btn-success': !eventInfo.has_ticket,
						}"
						class="btn btn-block pointer"
						style="font-weight: 600"
						@click="showTicketInfo(eventId)">
						{{ buttonText }}
					</div>
				</div>
			</div>
		</b-card>
	</div>
</template>

<script>
import WindowSizes from '@/mixins/WindowSizes';
import { FreeEvents as FreeEventsMessage, Dates } from '@/translations';

export default {
	name: 'EventDateCard',
	messages: [FreeEventsMessage, Dates],
	mixins: [WindowSizes],
	props: {
		eventInfo: {
			type: Object,
			default: () => ({}),
		},
		eventId: {
			type: String,
			default: '',
		},
	},
	computed: {
		eventDay() {
			return this.eventInfo.day;
		},
		eventMonth() {
			const month = this.eventInfo.month_short;
			return month.charAt(0).toUpperCase() + month.slice(1);
		},
		eventTime() {
			return `${this.eventInfo.time.start} - ${this.eventInfo.time.end}`;
		},
		location() {
			return this.translate(`${this.eventInfo.code_name}_location`);
		},
		stateCity() {
			return this.translate(`${this.eventInfo.code_name}_state_city`);
		},
		extraInfo() {
			return this.translate(`${this.eventInfo.code_name}_info`);
		},
		buttonText() {
			if (this.eventInfo.has_ticket) {
				return this.translate('space_reserved');
			}
			return this.translate('reserve_space');
		},
		eventDayStyle() {
			if (this.eventInfo.has_ticket) {
				return {
					background: '#b4d9c1',
				};
			}
			return {
				background: '#e1e1e1',
			};
		},
		headerStyle() {
			if (this.eventInfo.has_ticket) {
				return {
					background: '#dbf2e3',
				};
			}
			return {
				background: '#eeeeee',
			};
		},
	},
	methods: {
		showTicketInfo(id) {
			this.$emit('showTicketInfo', id);
		},
	},
};
</script>
<style scoped>
.card-shadow {
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	border: none !important
}
.event-day-text {
	font-size: 11px;
    line-height: 12px;
}
.card-hover:hover .card-hover-mobile:hover{
	background-color: rgba(25, 25, 25, 0.2);
}
.card-hover {
	max-height: 90px;
	height: 90px;
}
.card-hover-mobile {
	max-height: 150px;
	height: 150px;
}
.card-thumbnail-containter {
	max-width: 185px;
	height: 70px;
	background-size: cover;
	background-position: center;
}
.card-text-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	padding: 0.5rem;
	padding-top: 0rem;
	padding-bottom: 0rem;
}
.event-day{
	font-weight: bold;
}
.btn-green {
	background-color: #dbf2e3;
    border-color: #cdedd8;
    color: #28623c;
}
</style>
